const env = {}
const PROD = true
const URL = PROD?"https://api.fc777th.app":'http://localhost'
env.url = URL
env.parseServerUrl = `${URL}:1337/parse`
env.appId = "97dd70d3-da0a-432a-9dc9-01ef5a06c6a9"
env.masterKey = "597e1c2d-21f6-40d4-8748-29a5f2f30f98"
env.javascriptKey = "bdbd0236-bc83-457d-a5b0-08a273859dbb"
env.lineURL = '#'
env.lineName = '@fc777th'
env.telegramURL = '#'
env.whatappURL = '#'
env.appName = 'FC777TH'
env.version = "1.0.1"
export default env